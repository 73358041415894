<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" cols="12">
        <h1 class="font-weight-bold mb-0 text-primary">Overview</h1>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <b-card>
          <h4>Total Revenue and Marketing Costs</h4>
          <hr>
          <TotalRevenueAndMarketingChart :data="single.totalRevenueAndMarketingCostDatasets" :isLoading="isLoading"/>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <h4>Platforms</h4>
          <hr>
          <PlatformBarChart :data="single.platformDatasets" :isLoading="isLoading"/>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'

// Charts
import PlatformBarChart from '../../components/Charts/PlatformBarChart'
import TotalRevenueAndMarketingChart from '../../components/Charts/TotalRevenueAndMarketingChart'

import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser],
  data: () => ({}),
  created () {
    this.fetch()
  },
  components: {
    FriendlyDate,
    
    // Charts
    PlatformBarChart,
    TotalRevenueAndMarketingChart
  },
  mounted() {

  },
  computed: {
    ...mapGetters('overview', ['single', 'isLoading'])
  },
  methods: {
    ...mapActions('overview', ['fetch']),
  }
}
</script>

<style>

</style>
